// const url = 'http://localhost:5000';
const url = 'https://api.ayubazar.in';

const Requests = {
    GET_ALL_COMPANY: url + '/company',
    GET_ALL_COMPANIES_ONLY: url + '/company/admin-companies',
    GET_COMPANY_IMAGES: url + '/company-images/',
    EDIT_COMPANY: url + '/company/',
    ADD_COMPANY: url + '/company',
    ADD_PRODUCT: url + '/product',
    GET_ALL_PRODUCTS: url + '/product',
    CREATE_COUPON: url + '/coupon/generate-coupon',
    GET_ALL_COUPONS: url + '/coupon/get-all-coupon',
    EDIT_COUPON: url + '/coupon/edit-coupon',
    DELETE_COUPON: url + '/coupon/delete-coupon/',
    GET_PRODUCT_IMAGE: url + '/product-images/',
    DISABLE_PRODUCT: url + '/product/disable-product/',
    ENABLE_PRODUCT: url + '/product/enable-product/',
    CREATE_OFFER: url + '/offer/create-offer/',
    GET_DISCOUNT_OFFER: url + '/offer/',
    EDIT_PRODUCT: url + '/product/edit-product/',
    LOGIN_ADMIN_USER: url + '/admin-user/login',
    VALIDATE_USER: url + '/admin-user/validate',
    GET_BLOG_IMAGE: url + '/blog-images/',
    GET_ALL_BLOGS: url + '/blog/',
    ADD_BLOG: url + '/blog/add-blog',
    EDIT_BLOG: url + '/blog/edit-blog/',
    DELETE_BLOG: url + '/blog/delete-blog/',
    ADD_CATEGORY: url + '/category',
    GET_ALL_CATEGORIES: url + '/category',
    GET_ALL_CONTACTS: url + '/contact',
    GET_ALL_ORDERS: url + '/admin-orders',
    ORDER_READY_FOR_DELIVER: url + '/admin-orders/',
    DELETE_ORDER: url + '/admin-orders/delete-order/',
    GET_ALL_CANCEL_REQUEST_ORDERS: url + '/admin-orders/cancel-orders',
    GET_ALL_ON_THE_WAY_ORDERS: url + '/admin-orders/on-the-way-orders',
    GET_DELETED_ORDERS: url + '/admin-orders/deleted-orders',
    ACCEPT_ORDER_CANCEL_REQUEST: url + '/admin-orders/accept-cancel-order/',
    REJECT_ORDER_CANCEL_REQUEST: url + '/admin-orders/reject-cancel-order/',
    ORDER_DELIVERED: url + '/admin-orders/order-delivered/',
    ORDER_DETAILS_IN_EXCEL: url + '/admin-orders/orders-detail-in-excel/',
    ORDER_EXCEL_DETAILS: url + '/admin-orders/order-excel-details',
    GET_MONTHLY_REPORT: url + '/admin-orders/get-monthly-report',
    ADD_PRODUCTS_THROUGH_EXCEL: url + '/product/add-products-through-excel',
    BEST_SALING_PRODUCT: url + '/product/best-selling-product/',
    TOGGLE_PRODUCT_COD: url + '/product/product-cod-available/',
    GET_ALL_REVIEWS: url + '/review/get-all-review',
    ADD_ADMIN_REVIEW: url + '/review/add-review-by-admin',
    DELETE_PRODUCT_REVIEW: url + '/review/delete-review/',
    ADD_SUB_CATEGORY: url + '/sub-category/',
    GET_ALL_SUB_CATEGORY: url + '/sub-category/',
    EDIT_CATEORY: url + '/category/',
    EDIT_SUB_CATEGORY: url + '/sub-category/',
    DELETE_PRODUCT : url + '/product/',
    DELETE_COMPANY : url + '/company/'
}

export default Requests;