const ActionType = {
    COMPANY: "COMPANY",
    COMPANIES: "COMPANIES",
    CATEGORY: "CATEGORY",
    PRODUCTS: "PRODUCTS",
    COUPONS: "COUPONS",
    CONFIRMATIONDIALOG: "CONFIRMATIONDIALOG",
    OFFERDISCOUNT: "OFFERDISCOUNT",
    DIALOG: "DIALOG",
    USER: "USER",
    TOKEN: "TOKEN",
    BLOGS: "BLOGS",
    ORDERS: "ORDERS",
    ORDER: "ORDER",
    REPORT: "REPORT",
    CURRENTORDERS: "CURRENTORDERS",
    REVIEWS: "REVIEWS",
    SUBCATEGORIES:"SUBCATEGORIES"
}

export default ActionType;